<template>
  <div class="weakTestPaper">
    <div class="header">
      <div class="header-box">
        <goBack></goBack>
        <span>薄弱项训练</span>
      </div>
    </div>
    <div class="content">
      <div class="header-tips">
        <div class="title">
          知识点范围<span>点击单个知识点可对该知识点单独选题</span>
        </div>
        <el-button type="primary" plain @click="goBack()"
          >添加知识点<i class="el-icon-plus el-icon--right"></i
        ></el-button>
      </div>

      <div class="know-list-box">
        <template v-for="(v, i) in getSubjectKnow.val">
          <el-radio
            :key="i"
            v-model="knowRadio"
            class="know-list"
            :label="v.knowledgeId"
            @change="searchList"
          >
            {{ v.knowledgeName }}
            <span class="scoreRateAvg">{{ v.scoreRateAvg | setNumber }}</span>
          </el-radio>
        </template>
      </div>
      <div class="number">
        本知识点下已选<span>{{ knowNum }}</span
        >道题
      </div>
      <div class="option-box">
        <el-select
          v-model="difficulty"
          placeholder="请选择"
          @change="searchList"
        >
          <el-option
            v-for="item in difficultyList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select v-model="question" placeholder="请选择" @change="searchList">
          <el-option
            v-for="item in questionList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div v-if="loading" v-loading="loading" class="loading-box"></div>
      <div v-if="!loading" class="question-box">
        <div v-for="(v, i) in examquestionData" :key="i" class="question-list">
          <div class="question-id">试题ID：{{ v.id }}</div>
          <div style="padding: 0 12px 12px" v-html="v.question"></div>
          <div class="btm-box">
            <div class="tips">
              <span> 收录于{{ v.createTime | setTimeStyle }}</span>
              <span>{{ v.typeName }}</span>
              <span> {{ v.difficulty | setName(difficultyListCopy) }}</span>
            </div>

            <div class="btn-box">
              <span :class="{ succ: v.isRelation }" @click="relation(v)">
                <template v-if="v.isRelation == 1">已</template>收藏</span
              >
              <span @click="show(v)">解析</span>
              <span @click="situation(v)">考情</span>
              <el-button
                v-if="planQuestionList.indexOf(v.id) == -1"
                type="primary"
                class="btn-all"
                @click="exampaperplanquestion(v)"
              >
                加入试题篮
                <i class="el-icon-plus el-icon--right"></i>
              </el-button>
              <el-button
                v-else
                type="info"
                @click="exampaperplanquestionDel(v)"
              >
                移除试题篮
                <i class="el-icon-minus el-icon--right"></i>
              </el-button>
            </div>
          </div>
          <!-- 考情 -->
          <div v-if="situationArr.indexOf(v.id) != -1" class="situation-box">
            <template
              v-if="v.situation && v.situation.anyExamUseQuestion.length == 0"
            >
              数据尚未同步
            </template>
            <template v-else>
              <p>
                <span class="situation-box-name">考情：</span>本题共
                <span>{{ v.situation.countReply }}</span> 人使用,共{{
                  v.situation.examNum
                }}场考试
              </p>
              <div
                v-for="(m, n) in v.situation.anyExamUseQuestion"
                :key="n"
                class="situation-list"
              >
                <div class="situation-list-left">
                  <p>{{ m.examName }}</p>
                  <div>
                    <span class="situation-list-name">{{ m.examDate }}</span>
                    <span> | </span>
                    <span class="situation-list-name"
                      >共{{ m.countReply }}人参考</span
                    >
                    <span> | </span>
                    <span class="situation-list-name">{{ m.schoolLevel }}</span>
                  </div>
                </div>
                <div class="situation-list-right">
                  {{ m.scoreRate | setNumber }}
                </div>
              </div>
            </template>
          </div>
          <div v-if="showListArr.indexOf(v.id) != -1" class="analysis-box">
            <div v-if="v.newQuestion" class="new-question-box">
              <p v-if="v.newQuestion" class="question-tips">
                当前试题没有找到答案解析，已推荐同类题
              </p>
              <div class="analysis-item">
                【同类题】
                <div v-html="v.newQuestion"></div>
              </div>
              <el-button
                v-if="planQuestionList.indexOf(v.newId) == -1"
                type="primary"
                class="btn-all"
                @click="exampaperplanquestion(v, 1)"
              >
                加入试题篮
                <i class="el-icon-plus el-icon--right"></i>
              </el-button>
              <el-button
                v-else
                type="info"
                @click="exampaperplanquestionDel(v, 1)"
              >
                移除试题篮
                <i class="el-icon-minus el-icon--right"></i>
              </el-button>
            </div>

            <div
              v-if="v.knowledgeNames && v.knowledgeNames.split('#').length > 0"
              class="know-box analysis-item"
            >
              【知识点】
              <div v-for="(m, n) in v.knowledgeNames.split('#')" :key="n">
                {{ m }}
              </div>
            </div>
            <div v-if="v.answer && v.answer.length > 0" class="analysis-item">
              【答案】
              <div v-html="v.answer"></div>
            </div>
            <div
              v-if="v.analysis && v.analysis.length > 0"
              class="analysis-item"
            >
              【解答】
              <div v-html="v.analysis"></div>
            </div>
            <div v-if="v.analyse && v.analyse.length > 0" class="analysis-item">
              【分析】
              <div v-html="v.analyse"></div>
            </div>
            <div v-if="v.ability && v.ability.length > 0" class="analysis-item">
              【点评】
              <div v-html="v.ability"></div>
            </div>
            <!-- <div class="id-box">学科id：{{ v.xuekeId }}</div> -->
          </div>
        </div>
      </div>
      <div v-if="examquestionData.length == 0 && !loading" class="none-list">
        <img src="@/static/resources/none.svg" alt="" />
        <p>暂未找到数据</p>
      </div>
      <el-pagination
        v-if="
          (current > 1 || examquestionData.length > 0) && !loading && totalType
        "
        background
        layout="prev, pager, next"
        :total="total"
        :current-page="current"
        @current-change="pageList"
      >
      </el-pagination>
    </div>
    <testBasket
      :key="componentsKey"
      :plan-id="planId"
      :plan-type="planType"
      @setQuestionList="setQuestionList"
      @setPlanid="setPlanid"
      @getNewList="getNewList"
      @setQuestionKnowledge="setQuestionKnowledge"
    ></testBasket>
  </div>
</template>

<script>
import {
  // getbookchapterbyid,
  // getbooklistbylevelandsubject,
  examquestionSearch,
  exampaperplanquestionDel,
  exampaperplanquestion,
  situation,
  answer,
  examquestionrelationMy,
  examquestionrelationDel,
} from "@/core/api/resource/knowledgePointsPaper";
import goBack from "@/components/GoBack";
import { getquestiontype } from "@/core/api/resource/breakdownPaper";
import { mapGetters } from "vuex";
import testBasket from "@/views/resource/knowledgePointsPaper/components/testBasket.vue";
import { difficultyList, difficultyListCopy } from "@/core/util/constdata";
export default {
  name: "WeakTestPaper",
  components: {
    goBack,
    testBasket,
  },
  data() {
    return {
      planType: 4,
      knowRadio: "",
      difficulty: "0-1",
      question: -1,
      knowNum: 1,
      // 判断列表是否显示解析
      showListArr: [],
      // 判断是否显示考情
      situationArr: [],
      difficultyList: difficultyList,
      difficultyListCopy: difficultyListCopy,
      questionList: [],
      examquestionData: [],
      planQuestionList: [],
      questionKnowledge: [],
      loading: true,
      totalType: true,
      current: 1,
      componentsKey: 1,
      planId: 0,
    };
  },
  computed: {
    ...mapGetters(["getSubjectKnow"]),
  },
  watch: {
    questionKnowledge: {
      deep: true,
      handler() {
        this.knowNum = 0;
        this.questionKnowledge.forEach((index) => {
          if (index.knowledgeId == this.knowRadio) {
            this.knowNum++;
          }
        });
      },
    },
    knowRadio() {
      this.knowNum = 0;
      this.questionKnowledge.forEach((index) => {
        if (index.knowledgeId == this.knowRadio) {
          this.knowNum++;
        }
      });
    },
  },
  created() {
    this.knowRadio = this.getSubjectKnow.val[0].knowledgeId;
    this.getoptionList();
    this.getList();
  },
  mounted() {
    window.MathJax.typesetPromise();
  },
  updated() {
    window.MathJax.startup.defaultReady();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    setQuestionKnowledge(val) {
      // console.log(val);
      this.questionKnowledge = val;
    },
    setQuestionList(val) {
      // console.log(val);
      this.planQuestionList = val;
    },
    pageList(i) {
      this.current = i;
      this.getList();
    },
    searchList() {
      this.current = 1;
      this.getList();
    },
    // 移除试题篮
    exampaperplanquestionDel(val, type) {
      let data = {
        examPaperPlanId: this.planId,
        questionId: type ? val.newId : val.id,
        xuekeId: type ? val.newXuekeId : val.xuekeId,
      };
      exampaperplanquestionDel(data).then(() => {
        // console.log(res);
        this.$message({
          message: "移除成功！",
          type: "success",
        });
        // 调用 ，更新列表
        // v.isPlan = 0;
        this.componentsKey = new Date().getTime();
      });
    },
    relation(v) {
      let data = {
        examQuestionId: v.id,
        type: 1,
      };
      if (v.isRelation == 1) {
        this.$confirm("确认取消收藏当前试题吗", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            examquestionrelationDel(data).then(() => {
              v.isRelation = 0;
              this.$message({
                message: "已取消收藏",
                type: "success",
              });
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消“取消收藏”操作",
            });
          });
      } else {
        examquestionrelationMy(data).then(() => {
          // console.log();
          this.$message({
            message: "已收藏",
            type: "success",
          });
          v.isRelation = 1;
        });
      }
    },
    getNewList() {
      this.getList();
    },
    //设置planid
    setPlanid(planId) {
      this.planId = planId;
    },
    //添加到试题篮
    exampaperplanquestion(val, type) {
      let data = {
        planId: this.planId,
        questionId: type ? val.newId : val.id,
        xuekeId: type ? val.newXuekeId : val.xuekeId,
        knowledgeId: this.knowRadio,
      };

      exampaperplanquestion(data).then(() => {
        // console.log(res);
        this.$message({
          message: "加入成功",
          type: "success",
        });
        // val.isPlan = 1;
        this.componentsKey = new Date().getTime();
      });
    },
    // 切换是否显示页面列表
    show(v) {
      if (this.showListArr.indexOf(v.id) != -1) {
        this.showListArr.splice(this.showListArr.indexOf(v.id), 1);
        return;
      }
      answer(v.id).then((res) => {
        // console.log(res);
        if (res.data.data.id) {
          v.answer = res.data.data.answer;
          v.analyse = res.data.data.analyse;
          v.analysis = res.data.data.analysis;
          v.ability = res.data.data.ability;
          v.knowledgeNames = res.data.data.knowledgeNames;
          if (res.data.data.id != v.id) {
            v.newQuestion = res.data.data.question;
            // v.knowledgeNames = res.data.data.knowledgeNames;
            v.newId = res.data.data.id;
            v.newXuekeId = res.data.data.xuekeId;
          }
          if (this.showListArr.indexOf(v.id) == -1) {
            this.showListArr.push(v.id);
          } else {
            this.showListArr.splice(this.showListArr.indexOf(v.id), 1);
          }
        } else {
          this.$message({
            message: "未找到同类题和解析",
            type: "warning",
          });
        }
      });
    },
    //获取考情
    situation(v) {
      if (v.situation) {
        if (this.situationArr.indexOf(v.id) == -1) {
          this.situationArr.push(v.id);
        } else {
          this.situationArr.splice(this.situationArr.indexOf(v.id), 1);
        }
        return;
      }
      // console.log();
      situation(v.id).then((res) => {
        v.situation = res.data.data;
        if (this.situationArr.indexOf(v.id) == -1) {
          this.situationArr.push(v.id);
        } else {
          this.situationArr.splice(this.situationArr.indexOf(v.id), 1);
        }
        // console.log(res);
      });
    },
    getoptionList() {
      let arr = [
        {
          value: "0-1",
          label: "全部难度",
        },
      ];
      this.difficultyList = arr.concat(this.difficultyList);
      this.questionList = [];
      this.questionList.push({ label: "全部题型", value: -1, isClick: true });
      getquestiontype({
        levelId: this.getSubjectKnow.levelId,
        subjectId: this.getSubjectKnow.subjectId,
      }).then((res) => {
        // this.questionTypeData = [];
        res.data.data.forEach((index) => {
          this.questionList.push({
            value: index.name,
            label: index.name,
            isClick: false,
          });
        });
        // this.setTableData();
      });
    },
    onValue(list) {
      let value = "";
      list.forEach((index) => {
        if (index.isClick) {
          value = index.value;
        }
      });
      return value;
    },
    getList() {
      let data = {
        size: 10,
        current: this.current,
        level: this.getSubjectKnow.levelId,
        subjectId: this.getSubjectKnow.subjectId,
        // planId: this.planId,
        question: "",
        chapterIds: "",
        hasAnswer: 0,
        noImg: 1,
      };
      this.situationArr = [];
      this.showListArr = [];
      data.isSingle = 1;
      // if (this.treeType) {
      data.isUnion = 1;
      //题型
      data.typeName = this.question;
      // 难度
      data.difficulty = this.difficulty;
      // 年份
      data.year = -1;
      // 考试类型
      data.examType = -1;
      // 排序  最新  最热
      data.sortType = 1;
      data.knowledgeIds = [this.knowRadio];
      this.loading = true;
      examquestionSearch(data)
        .then((res) => {
          this.loading = false;
          this.examquestionData = res.data.data.records;
          // this.examquestionData.forEach(index => {
          //   index.createTime = index.createTime.replace("T", " ");
          // });
          if (res.data.data.total < 50) {
            this.total = 50;
          } else {
            this.total = res.data.data.total;
          }
          if (res.data.data.total < 10 && this.current == 1) {
            this.totalType = false;
          } else {
            this.totalType = true;
          }

          this.showListArr = [];
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss">
.weakTestPaper {
  .option-box {
    .el-select {
      input {
        width: 120px;
        border: none;
        color: #000;
        text-align: right;
      }
      i {
        color: #000;
      }
    }
  }
}
</style>
<style scoped lang="scss">
.weakTestPaper {
  .header {
    border-bottom: 1px solid #dddddd;
  }
  .content {
    width: 1220px;
    margin: 32px auto;

    .question-box {
      margin: 20px 0;
      .situation-box {
        padding: 12px;
        p {
          color: #606266;
        }
        .situation-box-name {
          color: $primary-color;
        }

        .situation-list {
          padding: 12px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: 1px solid #e5e5e5;
          margin-bottom: 12px;
          .situation-list-left {
            p {
              font-size: 16px;
              line-height: 24px;
              color: #000000;
            }
            .situation-list-name {
              color: #2196f3;
            }
          }
          .situation-list-right {
            color: #2196f3;
          }
        }
      }
      .analysis-box {
        .new-question-box {
          display: flex;
          align-items: flex-end;
          flex-wrap: wrap;
          padding: 12px;
          border-bottom: 1px dashed #dddddd;
          .question-tips {
            width: 100%;
            color: $primary-color;
          }
          .analysis-item {
            width: 80%;
            padding: 0px;
            border-bottom: none;
          }
        }
        .know-box {
          display: flex;
          align-items: center;
          div {
            color: #262626;
            padding: 0 5px;
            border-radius: 3px;
            margin-left: 12px;
            background: #e6f8f6;
          }
        }
      }
      .analysis-item {
        border-bottom: 1px dashed #dddddd;
        padding: 12px;
        &:last-child {
          border-bottom: none;
        }
      }
      .id-box {
        padding: 12px;
      }
      .btm-box {
        width: 100%;
        // position: absolute;
        // bottom: 0;
        // left: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        padding-left: 24px;
        background: #f5f5f5;
        .tips {
          > span {
            padding-right: 12px;
            // cursor: pointer;
          }
        }
        .succ {
          color: $primary-color;
        }
        .el-button {
          height: 40px;
          border-radius: 0 !important;
          width: 140px;
        }
        .btn-box {
          > span {
            padding-right: 12px;
            cursor: pointer;
          }
        }
        p {
          color: $primary-color;
          margin-bottom: 0;
        }
      }
      .loading-box {
        width: 100%;
        height: 600px;
      }
      .question-list {
        width: 100%;
        border-radius: 4px;
        margin-bottom: 20px;
        border: 1px solid $primary-color;
        padding-top: 12px;
        overflow: hidden;
        position: relative;
        .question-id {
          color: $primary-color;
          padding: 0 12px;
        }
      }
    }
    .none-list {
      img {
        width: 400px;
        display: block;
        margin: 20px auto;
      }
      p {
        text-align: center;
        color: #999999;
      }
    }
    .option-box {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .el-select {
        margin-left: 16px;
      }
    }
    .number {
      span {
        color: $primary-color;
        font-weight: bold;
      }
    }
    .know-list-box {
      background: rgba(0, 0, 0, 0.04);
      border-radius: 4px;
      padding: 12px 64px;
      display: flex;
      flex-wrap: wrap;
      margin: 30px 0;
      max-height: 200px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
      }

      ///*<!--修改 滑块 -->*/
      &::-webkit-scrollbar-thumb {
        background-color: $primary-color;
      }
      .scoreRateAvg {
        margin-left: 20px;
      }
      .know-list {
        width: 33%;
        margin: 10px 0;
      }
    }
    .header-tips {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        font-weight: bold;
        font-size: 16px;
        font-family: PingFang SC;
      }
      .el-button {
        border-color: $primary-color;
        color: $primary-color;
        background: #ffffff;
      }
      span {
        // color: rgba(0, 0, 0, 0.6);
        margin-left: 30px;
        font-size: 12px;
        font-weight: 100;
      }
    }
  }
  .header-box {
    width: 1220px;
    margin: 17px auto;
    font-size: 16px;
  }
}
</style>
